<template>
  <a-form :form="form" @submit="onSubmit">
    <a-form-item label="Имя">
      <a-input v-decorator="[
          'FirstName',
          { initialValue: formData.FirstName,
          rules: [{ required: true, message: 'Пожалуйста заполните Имя' }] },
        ]" />
    </a-form-item>
    <a-form-item label="Фамилия">
      <a-input v-decorator="[
          'LastName',
          { initialValue: formData.LastName,
          rules: [{ required: true, message: 'Пожалуйста заполните Фамилию' }] },
        ]" />
    </a-form-item>
    <a-form-item label="Номер телефона">
      <InputMask
        class="ant-input"
        :mask="['### (##) ###-##-##']"
        v-decorator="[
              'Phone',
              { initialValue: formData.Phone,
              rules: [{ required: true, message: 'Пожалуйста заполните номер телефона' }] },
            ]"
        @input="handleChange"
        placeholder="XXX (XX) XXX-XX-XX"
      />
    </a-form-item>
    <a-form-item label="Электронная почта">
      <a-input
        v-decorator="[
          'Email',
          { initialValue: formData.Email,
          rules: [{ required: true, message: 'Пожалуйста заполните Электронная почту' }] },
        ]" />
    </a-form-item>

    <a-form-item v-if="role === 'admin'" label="Роль">
      <a-select
        @change="selectRole"
        placeholder="Роль"
        v-decorator="[
          'Role',
          { initialValue: formData.Role,rules: [{ required: true,
          message: 'Пожалуйста выберите Роль' }] }, ]">
        <a-select-option
          v-for="role in roles"
          :key="role"
          :value="role">
          {{ role }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item label="Пароль">
      <a-input-password v-decorator="[
          'Password',
          { rules: [{ required: false, message: 'Пожалуйста заполните Пароль' }] },
        ]" />
    </a-form-item>
    <a-form-item label="Пароль">
      <a-input-password v-decorator="[
          'Password_confirmation',
          { rules: [{ required: false, message: 'Пожалуйста заполните Пароль' }] },
        ]" />
    </a-form-item>
    <a-form-item>
      <a-button type="primary" :loading="loading" @click="onSubmit">
        Сохранить
      </a-button>
      <a-button style="margin-left: 10px;">
        <router-link :to="{ name: 'users' }">Отмена</router-link>
      </a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import { TheMask } from 'vue-the-mask';
import { formatResponseValidatorFields } from '../../helpers';

export default {
  components: { InputMask: TheMask },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal_create' });
  },
  data() {
    return {
      loading: false,
      roles: [],
      formData: [],
    };
  },
  computed: {
    role() {
      const { Role } = this.$store.getters['user/user'];
      return Role;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$api.getUser(this.$router.currentRoute.params, ({ data }) => {
        this.formData = data.data;
      });
      this.$api.getRoles(false, ({ data }) => {
        this.roles = data.data;
      });
    },
    handleChange(value) {
      this.form.setFieldsValue({
        Phone: value,
      });
    },
    selectRole(value) {
      this.form.setFieldsValue({
        Role: value,
      });
    },
    onSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          this.$api.saveUser(this.$router.currentRoute.params, values, () => {
            this.$router.push({ name: 'users' });
            this.loading = false;
          }, ({ data, status }) => {
            const fields = formatResponseValidatorFields(data, values);
            this.loading = false;
            if (status === 422) {
              this.form.setFields(fields);
            }
          });
        }

        this.loading = false;
      });
    },
  },
};
</script>
